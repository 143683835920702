import Blockly from 'node-blockly/browser';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {updateCurrentWorkspace} from '../store/designs/DesignsAction';
import Button from './Button';
import Logo from './Logo';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fileInputButton = React.createRef();
  }

  /**
   * Processes one uploaded file
   * @param {event} event
   */
  importFile(event) {
    const fileList = event.target.files;
    const {0: file} = fileList;
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = () => {
      const xmlString = fileReader.result;
      const xmlBlock = Blockly.Xml.textToDom(xmlString);
      const {workspaceXml} = this.props;
      const workspace = Blockly.Xml.textToDom(workspaceXml);
      workspace.appendChild(xmlBlock);
      const workspaceString = Blockly.Xml.domToText(workspace);
      this.props.dispatch(updateCurrentWorkspace(workspaceString));
    };
  }

  clickFileUploadButton() {
    const current = this.fileInputButton;
    current.click();
  }

  logWorkspace() {
    console.log(this.props.workspaceXml);
  }

  render() {
    return (
      <header className="app__header">
        <Logo/>
        <div className="app__header__info">
          <div>
            <h1>Touchstone2 </h1>
            <p> Re-implementation of Touchstone2.
              Work-in-progress.</p>
          </div>
          {/* <Button text="Save Workspace" onChange={() => this.logWorkspace()}/>*/}
          {/* <Button text="Preregistration"/>*/}
          <Button
            text="Import Design"
            onChange={() => this.clickFileUploadButton()}
            additionalClassBackground={['button--float-right']}
          />
          <input
            hidden={true} ref={input => (this.fileInputButton = input)}
            onChange={(event) => this.importFile(event)} type={'file'}
          />
        </div>
      </header>

    );
  }
}

Header.propTypes = {
  dispatch: PropTypes.func,
  workspaceXml: PropTypes.string,
};

/**
 * Map state to props
 * @param {object} state
 * @return {{trialtable: *}}
 */
function mapStateToProps(state) {
  return {
    workspaceXml: state.designs.workspaceXML,
  };
}

export default connect(mapStateToProps)(Header);
