import PropTypes from 'prop-types';
import React from 'react';


class VerticalEntry extends React.PureComponent {
  constructor(props) {
    super(props);
    this._onMouseDown = this._onMouseDown.bind(this);
  }

  state = {
    indicatorClass: 'v-list__indicator',
  };

  static propTypes = {
    annotation: PropTypes.any,
    clickActive: PropTypes.bool,
    entry: PropTypes.object,
    id: PropTypes.string,
    onClickDown: PropTypes.func,
    onClickUp: PropTypes.func,
    update: PropTypes.func,
  };


  _onMouseDown(entry) {
    const {onClickDown, id} = this.props;
    this.setState({
      indicatorClass: 'v-list__indicator v-list__indicator--red',
    });
    onClickDown(entry, id);
  }

  _onMouseUp(entry) {
    const {onClickUp} = this.props;
    this.setState({
      indicatorClass: 'v-list__indicator',
    });
    onClickUp(entry);
  }

  _hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  _intToARGB(i) {
    const c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }

  _renderAnnotations() {
    const {annotation} = this.props;
    if (annotation) {
      return (
        <div
          className="annotation"
          style={{backgroundColor: `#${ this._intToARGB(this._hashCode(annotation.name))}`}}
        >
          <div className="annotation__text">{annotation.name}</div>
        </div>
      );
    }
  }

  _onMouseOver() {
    const {clickActive} = this.props;

    if (clickActive) {
      this.setState({
        indicatorClass: 'v-list__indicator v-list__indicator--red',
      });
    }
  }

  _renderIndicator() {
    const {entry, update} = this.props;
    const {indicatorClass} = this.state;
    return (
      <div>
        {this._renderAnnotations()}
        <div
          className={indicatorClass}
          onMouseEnter={() => update(entry)}
          onMouseDown={() => this._onMouseDown(entry)}
          onMouseUp={() => this._onMouseUp(entry)}
          onMouseOver={() => this._onMouseOver(entry)}
        />
      </div>
    );
  }


  render() {
    const {entry} = this.props;
    const event = new Date(entry.date);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };


    return (
      <>
        {this._renderIndicator()}
        <div className="v-list__date">
          {event.toLocaleString('en-DE', options)}
        </div>
      </>
    );
  }
}

export default VerticalEntry;
