/**
 * URLs and general settings
 */
export const API = {
  'UrlLog': 'http://logs.eval-m2hci-hcid.touchstone2.org/index.php',
};


export const COMPONENT = {
  STATES: {
    HISTORY_ELEMENT: {
      INIT: 'v-list__element',
      CLICK: 'v-list__element v-list__element--grabbing'
    }
  }
}
