import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {TitleTexts} from '../constants/index';
import Help from './Help';
import TitlePane from './TitlePane';
import MiniChart from './modules/PowerAnalysis/MiniChart';
import PowerChart from './modules/PowerAnalysis/PowerChart';
import BasicChart from './modules/PowerAnalysis/BasicChart';
import TrialPreview from './modules/TrialTable/TrialPreview';

class Sidebar extends React.PureComponent {
  static propTypes = {
    sidebar: PropTypes.array,
  };


  render() {
    const {sidebar} = this.props;

    /**
     * Sidebar Components that dynamically can be loaded in
     * @type {{TrialPreview, MiniChart, Help, PowerChart}}
     */
    const components = {
      TrialPreview,
      MiniChart,
      Help,
      PowerChart,
      BasicChart,
    };


    return (

      <div className="index__sidebar">
        {
          sidebar.map(item => {
            const SidebarElement = components[item];
            return (
              <div key={item} className="index__sidebar-element">
                <TitlePane title={TitleTexts[item]}/>
                <div className="index__sidebar-content">
                  <SidebarElement/>
                </div>
              </div>
            );
          })
        }
      </div>

    );
  }
}

function mapStateToProps(state: Object) {
  return {
    sidebar: state.sidebar.order,
  };
}

export default connect(mapStateToProps)(Sidebar);
