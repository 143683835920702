import {combineReducers} from 'redux';
import app from './app/AppReducer';
import dataTransformation from './dataTransformation/DataTransformationReducer';

import designs from './designs/DesignsReducer';
import history from './history/HistoryReducer';
import power from './power/PowerReducer';
import sidebar from './sidebar/SidebarReducer';
import workspace from './workspace/WorkspaceReducer';
import sequence from './sequencing/SequencingReducer';


export default combineReducers({
  designs,
  power,
  sidebar,
  workspace,
  history,
  dataTransformation,
  app,
  sequence,
});
