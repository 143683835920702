import * as d3 from 'd3';
import Blockly from 'node-blockly/browser';
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {updateSidebar} from '../../../store/sidebar/SidebarAction';
import {sidebarConfig} from '../../../store/sidebar/sidebarConfigurations';


class PowerChart extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    clickedBlockId: PropTypes.any,
    designs: PropTypes.any,
    dispatch: PropTypes.func,
    powerDistributions: PropTypes.array,
  };

  componentDidMount() {
    this.draw();

    const {powerDistributions} = this.props;


    powerDistributions.forEach((item) => {
      const design = Blockly.getMainWorkspace()
        .getBlockById(item.id);

      // if (design && design.type !== 'design') {
      //   design = design.getRootBlock();
      // }
      if (design) {
        console.log(design.type);
        if (design.type === 'design') {
          this.updateData(item.id, design.getFieldValue('designColor'), item.data);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {clickedBlockId, powerDistributions} = this.props;

    this.checkIfAbleToUpdate(clickedBlockId, powerDistributions);

    powerDistributions.forEach((item) => {
      const design = Blockly.getMainWorkspace()
        .getBlockById(item.id);

      //  if (design && design.type !== 'design' && design.getRootBlock()) {
      //   console.log(design.getRootBlock());
      //   design = design.getRootBlock();
      // }

      if (design && design.type === 'design') {
        this.updateData(item.id, design.getFieldValue('designColor'), item.data);
      }
    });

    return true;
  }

  checkIfAbleToUpdate(clickedBlockId, powerDistributions) {
    if (Object.keys(clickedBlockId).length !== 0) {
      const design = Blockly.getMainWorkspace()
        .getBlockById(clickedBlockId);

      if (design && design.type === 'design') {
        this.updateParticipantHandle(clickedBlockId, parseInt(design.getFieldValue('numberOfParticipants')));
        this.updateXAxis(1); // TODO make this update to the proper multiple


        const powerData = powerDistributions.filter(item => item.id === clickedBlockId)[0];

        this.updateArea(clickedBlockId, design.getFieldValue('designColor'), powerData.data);
      }
    }
  }

  updateData(id, color, data) {
    this.removeDataLine(id); // TODO make it update properly
    this.removeDataDots(id);


    const dataLine = this.g.selectAll(`[data-line-id="${ id }"]`)
      .data([data])
      .enter()
      .append('g')
      .attr('data-line-id', id);

    dataLine.append('path')
      .attr('class', 'line clickable')
      .style('stroke', color)
      .style('fill', 'transparent')
      .merge(dataLine)
      .attr('d', (d) => this.line(d))
      .on('click', () => {
        // lib things
        const designBlock = Blockly.getMainWorkspace()
          .getBlockById(id);
        if (designBlock) {
          designBlock.select();
        }
      });
    dataLine.exit()
      .remove();

    const multipleCBs = ['test'];
    const cbData = [];

    for (let dIdx = 0; dIdx < data.length; dIdx++) {
      const d = data[dIdx];
      if (d.participant % multipleCBs[id] === 0) {
        cbData.push(d);
      }
    }

    this.tooltipDiv = d3.select('body')
      .append('div')
      .attr('class', 'd3-tooltip')
      .style('opacity', 1);

    this.g.selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 3.5)
      .style('fill', color)
      .attr('data-dot-id', id)
      .attr('cx', (d) => this.x(d.participant))
      .attr('cy', (d) => this.y(d.power))
      .on('mouseover', (d) => {
        this.tooltipDiv.transition()
          .duration(200)
          .style('opacity', .9);
        this.tooltipDiv.html(`${'Fully counterbalanced with<br>'}${ d.participant } Participants resulting in a <br> Power of ${ this.round(d.power, 3)}`)
          .style('left', `${d3.event.pageX + 10 }px`)
          .style('top', `${d3.event.pageY - 10 }px`);
      })
      .on('mouseout', (d) => {
        this.tooltipDiv.transition()
          .duration(500)
          .style('opacity', 0);
      });
  }

  round(number, decimals) {
    if (decimals < 1) {
      return Math.round(number);
    }
    else {
      return Math.round(number * (Math.pow(10, decimals))) / Math.pow(10, decimals);
    }
  }

  removeDataLine(id) {
    const dataLine = this.g.selectAll(`[data-line-id="${ id }"]`);
    dataLine.remove();
  }

  removeDataDots(id) {
    const dataLine = this.g.selectAll(`[data-dot-id="${ id }"]`);
    dataLine.remove();
  }

  updateXAxis(multiple) {
    const oldXAxis = this.g.selectAll('.axis--x');
    oldXAxis.remove();
    const newXAxis = d3.axisBottom(this.x)
      .tickValues(d3.range(0, 50, multiple));
    this.g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${ this.height })`)
      .call(newXAxis);
  }


  updateArea(id, color, data) {
    this.removeDataAreas();
    const area = d3.area()
      .x((d) => this.x(d.participant))
      .y0((d) => this.y(d.power))
      .y1((d) => this.y(d.power));

    this.g.append('path')
      .datum(data)
      .attr('fill', color)
      .style('opacity', 0.2)
      .attr('d', area)
      .attr('class', 'dataArea')
      .attr('data-area-id', id);
  }

  removeDataAreas() {
    const dataArea = this.g.selectAll('.dataArea');
    dataArea.remove();
  }


  updateParticipantHandle(designId, xValue) {
    this.particpantHandleLine
      .style('opacity', 1)
      .attr('x1', this.x(xValue))
      .attr('x2', this.x(xValue));
    this.particpantHandleCircle
      .style('opacity', 1)
      .attr('cx', this.x(xValue))
      .attr('data-x-value', this.x(xValue))
      .attr('data-design-id', designId);
  }


  draw() {
    const svg = d3.select('#power-chart');
    const margin = {
      top: 20,
      right: 0,
      bottom: 50,
      left: 50,
    };

    /*
    Init states
     */

    this.width = svg.attr('width') - margin.left - margin.right;
    this.height = svg.attr('height') - margin.top - margin.bottom;
    this.g = svg.append('g')
      .attr('transform', `translate(${ margin.left },${ margin.top })`);

    this.x = d3.scaleLinear()
      .range([0, this.width]);

    this.y = d3.scaleLinear()
      .range([this.height, 0]);

    this.line = d3.line()
      .curve(d3.curveBasis)
      .x((d) => this.x(d.participant))
      .y((d) => this.y(d.power));

    /*
    * x axis
    * */

    this.x.domain([0, 50]);
    const xAxis = d3.axisBottom(this.x)
      .tickValues(d3.range(0, 50, 5));
    this.g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${this.height})`)
      .call(xAxis);

    svg.append('text')
      .attr('transform',
        `translate(${ this.width / 2 } ,${this.height + margin.top + 40 })`)
      .style('text-anchor', 'middle')
      .text('Number of Participants');

    /*
    * y axis
    * */

    this.y.domain([0, 1]);

    this.g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3.axisLeft(this.y));

    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left + 50)
      .attr('x', 0 - (this.height / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Power');

    /*
    * power line
    * */
    this.g.append('line')
      .style('stroke', 'red')
      .attr('x1', this.x(0))
      .attr('y1', this.y(0.8))
      .attr('x2', this.x(50))
      .attr('y2', this.y(0.8));

    const redArea = this.g.append('g');
    redArea.append('svg:rect')
      .attr('width', this.x(50))
      .attr('height', this.y(0) - this.y(0.8))
      .style('fill', 'rgba(255,0,0, 0.2)')
      .attr('x', this.x(0))
      .attr('y', this.y(0.8));


    const drag = d3.drag()
      .on('start', null)
      .on('drag', () => {
        // TODO: Fix bug where participanthandle only moves for one time
        // move participantHandleCircle
        const {dx} = d3.event;
        const cur = this.particpantHandleCircle.attr('cx');
        const xNew = parseFloat(this.particpantHandleCircle.attr('data-x-value')) + dx;
        const X = parseFloat(cur) + dx;


        this.particpantHandleCircle.attr('data-x-value', xNew);
        console.log(xNew);
        const newParticipantNumber = Math.round(this.x.invert(xNew));
        console.log(newParticipantNumber);
        const currentParticipantNumber = Math.round(this.x.invert(X));
        //
        if (currentParticipantNumber !== newParticipantNumber) {
          this.particpantHandleLine.attr('x1', this.x(newParticipantNumber))
            .attr('x2', this.x(newParticipantNumber));
          this.particpantHandleCircle.attr('cx', this.x(newParticipantNumber));
        }
      })
      .on('end', () => {
        console.log('done');
        const newParticipantNumber = Math.round(this.x.invert(this.particpantHandleCircle.attr('cx')));
        const designId = this.particpantHandleCircle.attr('data-design-id');

        const designBlock = Blockly.getMainWorkspace()
          .getBlockById(designId);
        if (designBlock) {
          designBlock.setFieldValue(newParticipantNumber, 'numberOfParticipants');
        }
      });


    this.particpantHandleLine = this.g
      .append('line')
      .attr('x1', this.x(10))
      .attr('y1', this.y(0))
      .attr('x2', this.x(10))
      .attr('y2', this.y(1))
      .attr('stroke-width', 4)
      .attr('stroke', 'black')
      .style('opacity', 0);

    this.particpantHandleCircle = this.g.append('circle')
      .attr('cx', this.x(10))
      .attr('cy', this.y(0.2))
      .attr('r', 10)
      .attr('data-x-value', this.x(10))
      .attr('class', 'clickable')
      .attr('stroke-width', 2)
      .attr('stroke', 'black')
      .attr('fill', 'white')
      .style('opacity', 0)
      .call(drag);
  }


  render() {
    const {dispatch} = this.props;
    return (
      <>
        <p className="power-analysis-toggle blue-right clickable">
          <a
            href="#"
            onClick={() => dispatch(updateSidebar(sidebarConfig.DEFAULT))}
          >
            show less
          </a>
        </p>
        <div className="chart">
          <svg id="power-chart" width="810" height="300"/>
        </div>
      </>
    );
  }
}

/**
 * map State to Props
 * @param {object} state
 * @return {{blockly: *}}
 */
function mapStateToProps(state: Object) {
  return {
    powerDistributions: state.power.powerDistributions,
    clickedBlockId: state.designs.clickedBlockId,
    designs: state.designs.designData,
  };
}

export default connect(mapStateToProps)(PowerChart);
