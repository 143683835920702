import * as d3 from 'd3';
import Blockly from 'node-blockly/browser';
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {updateSidebar} from '../../../store/sidebar/SidebarAction';
import {sidebarConfig} from '../../../store/sidebar/sidebarConfigurations';
import {round} from '../../../constants/util';


class MiniChart extends React.Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  static propTypes = {
    clickedBlockId: PropTypes.any,
    designData: PropTypes.array,
    dispatch: PropTypes.func,
    powerDistributions: PropTypes.array,
  };


  componentDidMount() {
    const {clickedBlockId, powerDistributions} = this.props;

    this.draw();
    this.checkIfAbleToUpdate(clickedBlockId, powerDistributions);
  }

  componentDidUpdate(prevProps) {
    const {clickedBlockId, powerDistributions} = this.props;
    this.checkIfAbleToUpdate(clickedBlockId, powerDistributions);
    return true;
  }

  checkIfAbleToUpdate(clickedBlockId, powerDistributions) {
    if (Object.keys(clickedBlockId).length !== 0) {
      let design = Blockly.getMainWorkspace()
        .getBlockById(clickedBlockId);

      if (design && design.type !== 'design') {
        design = design.getRootBlock();
      }

      const [designPowerData] = powerDistributions.filter(item => item.id === clickedBlockId);

      if (design && designPowerData) {
        this.updateData(designPowerData, design.getFieldValue('designColor'), parseInt(design.getFieldValue('numberOfParticipants')));
      }
    }
  }


  removeMini() {
    const dataLine = this.gMini.selectAll('.mini-chart');
    dataLine.remove();
  }

  updateData(powerData, color, participants) {
    const {id, data} = powerData;
    const {designData} = this.props;

    this.removeMini();
    const dataLine = this.gMini.selectAll(`[data-line-id="${id}"]`)
      .data([data])
      .enter()
      .append('g')
      .attr('data-line-id', id)
      .attr('class', 'mini-chart');

    dataLine.append('path')
      .attr('class', 'line')
      .style('stroke', color)
      .style('fill', 'transparent')
      .merge(dataLine)
      .attr('d', (d) => this.lineMini(d));
    dataLine.exit()
      .remove();

    const [targetDesign] = designData.filter(design => design.designId === id);
    const {multiple} = targetDesign;

    const cbData = [];
    for (let dIdx = 0; dIdx < data.length; dIdx++) {
      const d = data[dIdx];
      if (d.participant % multiple === 0) {
        cbData.push(d);
      }
    }

    this.updateXAxis(multiple);

    this.gMini.selectAll('dot')
      .data(cbData)
      .enter()
      .append('circle')
      .attr('class', 'mini-chart')
      .attr('r', 3.5)
      .style('fill', color)
      .attr('data-dot-id', id)
      .attr('cx', (d) => this.xMini(parseInt(d.participant)))
      .attr('cy', (d) => this.yMini(parseFloat(d.power)));

    this.gMini
      .append('line')
      .attr('x1', this.xMini(participants))
      .attr('y1', this.yMini(0))
      .attr('x2', this.xMini(participants))
      .attr('y2', this.yMini(1))
      .attr('stroke-width', 2)
      .attr('stroke', 'black')
      .attr('class', 'mini-chart');


    let power = 0;
    for (let i = 0; i < data.length; i++) {
      const curElem = data[i];
      if (curElem.participant === parseFloat(participants)) {
        power = round(curElem.power, 2);
        break;
      }
    }
    // Todo: get the effect Size let pa = JSON.parse(localStorage.power) and input it;
    const effectSize = '0.25';
    const powerText = `${participants} participants yields the power of ${power} at effect size Cohen's f = ${effectSize}`;
    const powerTextContainer = document.querySelector('#mini-statistical-power-text');
    powerTextContainer.innerHTML = '';
    powerTextContainer.appendChild(document.createTextNode(powerText));
  }

  updateXAxis(multiple) {
    const oldXAxis = this.gMini.selectAll('.axis--x');
    oldXAxis.remove();
    const newXAxis = d3.axisBottom(this.xMini)
      .tickValues(d3.range(0, 50, multiple));
    this.gMini.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${this.heightMini})`)
      .call(newXAxis);
  }

  draw() {
    const {width, height} = this.container.current.getBoundingClientRect();

    this.svgMini = d3.select(this.container.current)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', `0 0 ${Math.min(width, height)} ${Math.min(width, height)}`)
      .attr('preserveAspectRatio', 'xMinYMin');

    this.marginMini = {
      top: 10,
      right: 0,
      bottom: 50,
      left: 50,
    };
    this.widthMini = width - this.marginMini.left - this.marginMini.right;
    this.heightMini = height - this.marginMini.top - this.marginMini.bottom;
    this.gMini = this.svgMini.append('g')
      .attr('transform', `translate(${this.marginMini.left - 10},${this.marginMini.top})`);

    /*
    X Axis
     */
    this.xMini = d3.scaleLinear()
      .range([0, this.widthMini]);
    this.xMini.domain([0, 50]);
    this.xAxisMini = d3.axisBottom(this.xMini)
      .tickValues(d3.range(0, 50, 5));
    this.gMini.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', `translate(0,${this.heightMini})`)
      .call(this.xAxisMini);

    this.svgMini.append('text')
      .attr('transform',
        `translate(${this.widthMini / 2} ,${
          this.heightMini + this.marginMini.top + 30})`)
      .style('text-anchor', 'middle')
      .text('Number of Participants')
      .style('font-size', '12px');

    /*
    Y Axis
     */
    this.yMini = d3.scaleLinear()
      .range([this.heightMini, 0]);
    this.yMini.domain([0, 1]);
    this.yAxisMini = d3.axisLeft(this.yMini)
      .tickValues(d3.range(0, 1.1, 0.2));

    this.gMini.append('g')
      .attr('class', 'axis axis--y')
      .call(this.yAxisMini);

    this.svgMini.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - this.marginMini.left + 50)
      .attr('x', 0 - (this.heightMini / 2))
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Power')
      .style('font-size', '12px');

    /*
    Power Line
     */
    this.redAreaMini = this.gMini.append('g');
    this.redAreaMini.append('svg:rect')
      .attr('width', this.xMini(50))
      .attr('height', this.yMini(0) - this.yMini(0.8))
      .style('fill', 'rgba(255,0,0, 0.2)')
      .attr('x', this.xMini(0))
      .attr('y', this.yMini(0.8));

    this.lineMini = d3.line()
      .curve(d3.curveBasis)
      .x((d) => this.xMini(parseInt(d.participant)))
      .y((d) => this.yMini(parseFloat(d.power)));
  }


  render() {
    const {dispatch} = this.props;
    const miniChartContainerStyle = {
      width: 'calc(29vw - 20px)',
      fallbacks: [
        {width: '-moz-calc(29vw - 20px)'},
        {width: '-webkit-calc(29vw - 20px)'},
        {width: '-o-calc(29vw - 20px)'},
      ],
      height: '150px',
    };
    return (
      <>
        <div className="chart">
          <div
            style={miniChartContainerStyle}
            ref={this.container}
          />
          <p id="mini-statistical-power-text">
            Please select an experimental design to see its statistical power.
          </p>
          <p className="power-analysis-toggle blue-right clickable">
            <a
              href="#"
              onClick={() => dispatch(updateSidebar(sidebarConfig.POWER))}
            >
              more...
            </a>
          </p>
        </div>
      </>
    );
  }
}


/**
 * map State to Props
 * @param {object} state
 * @return {{blockly: *}}
 */
function mapStateToProps(state: Object) {
  return {
    powerDistributions: state.power.powerDistributions,
    clickedBlockId: state.designs.clickedBlockId,
    designData: state.designs.designData,
  };
}

export default connect(mapStateToProps)(MiniChart);
