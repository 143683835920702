import {throttle} from 'redux-saga/effects';
import {UPDATE_SIDEBAR} from './SidebarAction';

/**
 * Blockly Sagas
 */

/**
 * This generator function
 *
 * @param {Object} action
 *
 */
const setSidebar = function* (action) {
  try {
    console.log(action);

  }
  catch (err) {
    console.log('Unable to set new Sidebar', err);
  }
};

const sidebarSaga = function* () {
};

export default sidebarSaga;
