import PropTypes from 'prop-types';
import React from 'react';
import {COMPONENT} from '../constants/settings';
import {
  addAnnotationStartingId,
  endAnnotationEntry,
} from '../store/history/HistoryAction';
import ComparisonBlock from './ComparisonBlock';
import VerticalEntry from './VerticalEntry';


class VerticalList extends React.PureComponent {
  state = {
    historyEntry: {},
    vListElementClass: COMPONENT.STATES.HISTORY_ELEMENT.INIT,
    clickActive: false,
  };

  static propTypes = {
    annotation: PropTypes.array,
    data: PropTypes.any,
    dispatch: PropTypes.func,
    toggle: PropTypes.bool,
  };

  _updateXML(historyEntry) {
    this.setState(
      {
        historyEntry,
      },
    );
  }

  _onClickDown(entry, id) {
    const {dispatch} = this.props;

    this.setState({
      vListElementClass: COMPONENT.STATES.HISTORY_ELEMENT.CLICK,
      clickActive: true,
    });

    dispatch(addAnnotationStartingId(entry, id));
  }

  _onClickUp(entry) {
    const {dispatch} = this.props;

    this.setState({
      vListElementClass: COMPONENT.STATES.HISTORY_ELEMENT.INIT,
      clickActive: false,
    });
    dispatch(endAnnotationEntry(entry));
  }

  _addAnnotation(annotation, entry) {
    return annotation.find(item => (entry.date === item.date_start || (entry.date > item.date_start && entry.date < item.date_end) || entry.date === item.date_end));
  }


  _renderList(data, annotation) {
    const {vListElementClass, clickActive} = this.state;
    return (
      <div className="canvas__item-compare">
        <div className="v-list">
          {data.events.map(entry => (
            <div
              className={vListElementClass}
              key={entry.date}
            >
              <VerticalEntry
                id={data.id}
                entry={entry}
                date={entry.date}
                annotation={this._addAnnotation(annotation, entry)}
                clickActive={clickActive}
                update={this._updateXML.bind(this)}
                onClickDown={this._onClickDown.bind(this)}
                onClickUp={this._onClickUp.bind(this)}
              />
            </div>
          ))}
        </div>
        <ComparisonBlock
          data={this.state.historyEntry}
          toggle={this.props.toggle}
        />
      </div>
    );
  }

  render() {
    const {data, annotation} = this.props;
    return (

      <div>
        {
          this._renderList(data, annotation)
        }
      </div>
    );
  }
}

export default VerticalList;
