

export const UPDATE_SIDEBAR = 'UPDATE_SIDEBAR';
export const SET_SIDEBAR = 'SET_SIDEBAR';


export const updateSidebar = (config) => ({
  type: UPDATE_SIDEBAR,
  config,
});
