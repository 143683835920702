import PropTypes from 'prop-types';
import React from 'react';

/**
 * Visual of the row of a Trial Table
 */
class TrialEntry extends React.PureComponent {
  static propTypes = {};

  /**
   * Builds classname for row
   * @return {*}
   */
  buildClassName() {
    const {classNameRow, children} = this.props;

    return `${classNameRow} trial-table__${children}`;
  }


  /**
   * Highlights the Classnames
   */
  highlightClassNames(e) {
    const {children} = this.props;
    e.stopPropagation();
    [...document.querySelectorAll(`.trial-table__${children}`)].forEach(item => {
      item.classList.toggle('trial-table__selected');
    });
  }

  /**
   * Render function of react,
   * @return {*}
   */
  render() {
    const {children} = this.props;
    if (!(children instanceof Array)) {
      return (
        <div
          className={this.buildClassName()}
          onClick={(e) => this.highlightClassNames(e)}>
          {children}
        </div>
      );
    }
    else {
      return children.map((item, index) => {
        return <TrialEntry key={`${item}${index}`}>{item}</TrialEntry>;
      });
    }
  }
}

TrialEntry.defaultProps = {
  classNameRow: 'trial-table__entry',
};

TrialEntry.propTypes = {
  children: PropTypes.any,
  classNameRow: PropTypes.string,
};


export default TrialEntry;
