import {all, fork} from 'redux-saga/effects';

import designsSaga from './designs/DesignsSaga';
import workspaceSaga from './workspace/WorkspaceSaga';
import powerSaga from './power/PowerSaga';
import sidebarSaga from './sidebar/SidebarSaga';
import playgroundFieldSaga from './playgroundField/PlaygroundFieldSaga';
import dataTransformationSaga
  from './dataTransformation/DataTransformationSaga';
import historySaga from './history/HistorySaga';
import appSaga from './app/AppSaga';
import sequenceSaga from './sequencing/SequencingSaga';

/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(designsSaga),
    fork(powerSaga),
    fork(workspaceSaga),
    fork(sidebarSaga),
    fork(playgroundFieldSaga),
    fork(dataTransformationSaga),
    fork(historySaga),
    fork(appSaga),
    fork(sequenceSaga),
  ]);
}
